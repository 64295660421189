import { call, select } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import {
  embedPlayerStateChanged,
  selectContentLibraryVideoPlayer,
} from 'features/content-library/contentLibrarySlice';
import {
  ContentLibraryVideoPlayer,
  ContentLibraryVideoPlayerRemotePayload,
} from 'features/content-library/types';

export function* embedPlayerStateChangeSaga(action: ReturnType<typeof embedPlayerStateChanged>) {
  if (action.meta.remoteInteraction) {
    return;
  }

  const videoPlayer: ContentLibraryVideoPlayer | undefined = yield select(
    selectContentLibraryVideoPlayer,
    action.payload.id
  );
  if (!videoPlayer) {
    return;
  }

  const defaultState: ContentLibraryVideoPlayerRemotePayload = {
    id: videoPlayer.id,
    currentTime: videoPlayer.currentTime,
    state: videoPlayer.state as 'playing' | 'paused',
    volume: videoPlayer.volume,
    muted: videoPlayer.muted,
    type: 'playback',
  };

  yield call(SignalingSocket.send, {
    event: 'changeVideoPlayerState',
    data: {
      ...defaultState,
      ...action.payload,
    },
  });
}
