import merge from 'lodash.merge';
import { logger } from 'utils/logger';
import { getDefaultAudioConstraints } from 'features/user-media/utils/getDefaultAudioConstraints';
import { getDefaultVideoConstraints } from 'features/user-media/utils/getDefaultVideoConstraints';

export const getUserMedia = async (constraints: MediaStreamConstraints = {}) => {
  // Create a new object to handle undefined `video` and `audio` cases
  const adjustedConstraints: MediaStreamConstraints = {
    audio: constraints.audio === undefined ? false : constraints.audio,
    video: constraints.video === undefined ? false : constraints.video,
  };

  const defaultConstraints = {
    audio: getDefaultAudioConstraints(),
    video: getDefaultVideoConstraints(),
  };

  const mergedConstraints = merge(defaultConstraints, adjustedConstraints);

  logger.remote({ tier: 1 }).info('Requesting user media', mergedConstraints);

  const stream = await navigator.mediaDevices.getUserMedia(mergedConstraints);
  const tracks = stream.getTracks();

  tracks.forEach((track) => {
    logger.remote({ tier: 1 }).info(`${track.kind} track settings:`, track.getSettings());
    logger.remote({ tier: 1 }).info(`${track.kind} track constraints:`, track.getConstraints());
  });

  return stream;
};
