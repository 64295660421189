import { call } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { SpeakerStoppedTalking } from 'features/streaming/types';
import { eventBus } from 'utils/eventBus';

export function* onSpeakerStoppedTalkingSaga(action: PayloadAction<SpeakerStoppedTalking>) {
  const { id: userId } = action.payload;

  yield call(eventBus.sendMessage, 'speakerStoppedTalking', { userId });
}
