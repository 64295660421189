import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';

export const useWhiteboard = () => {
  const featureEnabled = useFeatureFlag('whiteboard');

  const { hasPermissions } = usePermissions();
  const canManageEditWhiteboard = hasPermissions(PermissionTypes.manageEditWhiteboard);

  const canEditWhiteboard = hasPermissions(PermissionTypes.editWhiteboard);

  const isFeatureAvailable = featureEnabled && (canManageEditWhiteboard || canEditWhiteboard);

  return {
    canEditWhiteboard,
    canManageEditWhiteboard,
    isFeatureAvailable,
  };
};
