import { MenuItem } from '@mui/material';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import { useTranslation } from 'react-i18next';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';

const EndCallControl = () => {
  const { t } = useTranslation('room');

  const {
    leaveSession,
    endSession,
    endSessionAvailable,
    icon,
    leaveBreakoutAvailable,
    leaveSessionAvailable,
    leaveBreakout,
    singleControlHandler,
    singleControlLabel,
    menuLabel,
    availableControls,
    showNestedControls,
  } = useEndSessionControl();

  if (showNestedControls) {
    return (
      <ToolbarMenuItem
        data-testid="end-call-control-menu"
        menu
        overlay={menuLabel}
        ariaLabel={menuLabel}
        icon={icon}
        id="end-call"
      >
        {leaveBreakoutAvailable ? (
          <MenuItem onClick={leaveBreakout}>
            {t('toolbar.end_session_options.leave_breakout')}
          </MenuItem>
        ) : null}
        {leaveSessionAvailable ? (
          <MenuItem onClick={leaveSession}>
            {t('toolbar.end_session_options.leave_session')}
          </MenuItem>
        ) : null}
        {endSessionAvailable ? (
          <MenuItem
            sx={{
              color: 'error.main',
            }}
            onClick={endSession}
          >
            {t('toolbar.end_session_options.end_session')}
          </MenuItem>
        ) : null}
      </ToolbarMenuItem>
    );
  }

  if (availableControls.some(Boolean)) {
    return (
      <ToolbarMenuItem
        data-testid="end-call-control"
        overlay={singleControlLabel}
        ariaLabel={singleControlLabel}
        icon={icon}
        id="end-call"
        onClick={singleControlHandler}
      />
    );
  }

  return null;
};

export default EndCallControl;
