import {
  ContentLibraryFolder,
  ContentLibraryState,
  FileReceivedPayload,
  WhiteboardCreatedPayload,
} from 'features/content-library/types';

import {
  createFileProcessingMeta,
  getContentLibrary,
  normalizeFile,
} from 'features/content-library/utils/normalizeContentLibrary';

import { PayloadAction } from '@reduxjs/toolkit';

export const handleFileProcessing = (
  state: ContentLibraryState,
  action: PayloadAction<WhiteboardCreatedPayload | FileReceivedPayload>
) => {
  const updateParentFolder = (parentFolder: ContentLibraryFolder, fileId: string) => {
    parentFolder.childFileIds.push(fileId);
    parentFolder.childUploadIds = parentFolder.childUploadIds.filter((id) => id !== fileId);
  };

  const { status, id: fileId, personal } = action.payload;

  const library = getContentLibrary(state, personal);
  const file = normalizeFile(action.payload);
  const parentFolder = library.folders[file.folderId];

  // If the file has completed processing, remove its meta and update the file
  if (status === 'completed') {
    delete library.fileProcessingMeta[fileId];
    if (parentFolder) {
      updateParentFolder(parentFolder, fileId);
    }
    library.files[file.id] = file;
    return;
  }

  // Ensure processing meta exists and handle 'converting' status
  let processingMeta = library.fileProcessingMeta[fileId];
  if (!processingMeta) {
    processingMeta = createFileProcessingMeta(file, file.folderId);
    library.fileProcessingMeta[fileId] = processingMeta;
  }

  if (status === 'converting') {
    processingMeta.progress = 0;
    processingMeta.status = 'converting';
  }

  if (parentFolder) {
    updateParentFolder(parentFolder, fileId);
  }
  library.files[file.id] = file;
};
