import React from 'react';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { MobileMenuItemWrapperProps } from 'features/toolbar/types';

const WhiteboardControlMobile = ({ onClick }: MobileMenuItemWrapperProps) => {
  const { active, label, createWhiteboard, isFeatureAvailable } = useWhiteboardControl();

  if (!isFeatureAvailable) {
    return null;
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick?.(event);
    createWhiteboard();
  };

  return (
    <MobileMenuItem
      id="whiteboard-control"
      selected={active}
      icon="whiteboard"
      onClick={handleClick}
    >
      {label}
    </MobileMenuItem>
  );
};

export default WhiteboardControlMobile;
