import { whiteboardOpened } from 'features/layout/features/content/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal, selectActiveModalData } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';

const WhiteboardE2eeConfirmationModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const modalData = useAppSelector(selectActiveModalData);

  const handleOk = () => {
    dispatch(whiteboardOpened(modalData.whiteboardId));
    dispatch(closeModal('whiteboardE2eeConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('whiteboardE2eeConfirmation'));
  };

  return (
    <ConfirmationDialog
      open
      maxWidth="sm"
      title={t('whiteboard_e2ee_confirmation.title')}
      description={t('whiteboard_e2ee_confirmation.description')}
      cancelText={t('whiteboard_e2ee_confirmation.btn_cancel_label')}
      okText={t('whiteboard_e2ee_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default WhiteboardE2eeConfirmationModal;
