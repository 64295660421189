import React from 'react';
import { Box, Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import { styled, alpha, SxProps } from '@mui/material/styles';
import { chipClasses } from '@mui/material/Chip';

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'handRaised',
})(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.black, 0.7),
  fontSize: theme.typography.pxToRem(14),
  height: 26,
  textTransform: 'capitalize',
  transition: 'none',
  [`& .${chipClasses.labelMedium}`]: {
    padding: theme.spacing(0, 4),
  },
}));

type StreamBadgeProps = {
  label?: string | null;
  sx?: SxProps;
  ChipProps?: MuiChipProps;
};

export const StreamBadge = ({ label, ChipProps, sx = [] }: StreamBadgeProps) => {
  if (!label) {
    return null;
  }

  return (
    <Box
      className="hover-child"
      sx={[
        {
          position: 'absolute',
          left: 0,
          width: '100%',
          top: (theme) => theme.spacing(2),
          textAlign: 'center',
          padding: (theme) => theme.spacing(0, 2),
          zIndex: 3,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Chip label={label} {...ChipProps} />
    </Box>
  );
};
