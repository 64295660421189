export const hasFileExtension = (file: File): boolean =>
  /.jpeg|.jpg|.png|.gif|.webp/.test(file.name);

const defaultExtensionMap: { [key: string]: string } = {
  'image/png': '.png',
  'image/jpeg': '.jpeg',
  'image/jpg': '.jpg',
  'image/gif': '.gif',
  'image/webp': '.webp',
};

export const formatFileName = (file: File) => {
  if (hasFileExtension(file)) {
    return file.name;
  }

  return `${file.name}${defaultExtensionMap[file.type]}`;
};
