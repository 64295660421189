import { createAction } from '@reduxjs/toolkit';
import { CursorUpdatePayload, RemoteUpdatePayload } from 'utils/whiteboard/BoardStateManager';
import { WhiteboardCreatedPayload } from 'features/content-library/types';
import {
  WhiteboardCreatedOptions,
  SignalingWhiteboardOpenedPayload,
  WhiteboardAlreadyOpenedPayload,
} from './types';

export const whiteboardOpened = createAction<string>('layout/whiteboardOpened');
export const whiteboardClosed = createAction<string>('layout/whiteboardClosed');
export const whiteboardCreated = createAction<WhiteboardCreatedOptions>('layout/whiteboardCreated');

export const signalingWhiteboardOpened = createAction<SignalingWhiteboardOpenedPayload>(
  'signaling/whiteboardOpened'
);
export const signalingWhiteboardClosed = createAction('signaling/whiteboardClosed');
export const signalingWhiteboardCreated = createAction<WhiteboardCreatedPayload>(
  'signaling/whiteboardCreated'
);
export const signalingWhiteboardChanged = createAction<RemoteUpdatePayload>(
  'signaling/whiteboardChanged'
);

export const signalingWhiteboardCursorUpdated = createAction<CursorUpdatePayload>(
  'signaling/whiteboardCursorUpdated'
);
export const signalingWhiteboardAlreadyOpened = createAction<WhiteboardAlreadyOpenedPayload>(
  'signaling/whiteboardAlreadyOpened'
);
