export const base64ToFile = (packed: string, fileName: string = 'image.png') => {
  try {
    const byteString = atob(packed.split(',')[1]);

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }

    const mimeString = packed.split(',')[0].split(':')[1].split(';')[0];
    return new File([byteArray], fileName, { type: mimeString });
  } catch (e) {
    return null;
  }
};

export const urlToFile = async (url: string = '', fileName: string = 'image.png') => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  } catch (e) {
    return null;
  }
};
