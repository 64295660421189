import * as Sentry from '@sentry/react';
import { publisherJoined, videoroomError } from 'features/streaming/actions';
import { selectLocalAudioBroadcasting } from 'features/streaming/streamingSlice';
import { JanusJS } from 'lib/janus';
import { SignalingSocket } from 'services/signaling';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import { GENERIC_JANUS_ERROR } from 'utils/webrtc/errors';
import { RTCClient } from 'utils/webrtc';
import { BasePublishing } from 'utils/webrtc/publishing';
import { selectAudioSettings } from 'features/user-media/userMediaSlice';

export class MessageHandler {
  feed: BasePublishing;

  constructor(feed: BasePublishing) {
    this.feed = feed;
  }

  onMessage = (message: JanusJS.EvtMessage, jsep?: JanusJS.JSEP) => {
    const ignoredLogEvents = ['talking', 'stopped-talking'];
    const feed = this.feed.handle!;

    if (jsep) {
      const { quality } = selectAudioSettings(store.getState());

      feed.videoroom.handleRemoteJsep({
        customizeSdp: (remoteJsep) => {
          remoteJsep.sdp = remoteJsep.sdp.replace(
            'useinbandfec=1',
            `useinbandfec=1;usedtx=1;maxaveragebitrate=${quality}`
          );
        },
        jsep,
        error: GENERIC_JANUS_ERROR(`publish_local_feed_${feed.handle}`),
      });
    }

    const event = message.videoroom;

    if (!ignoredLogEvents.includes(event!)) {
      logger.remote({ action: true }).debug(`Got local message in publishing feed:`, message);
    }

    switch (event) {
      case 'joined': {
        store.dispatch(publisherJoined({ handle: feed.handle, message, kind: this.feed.kind }));
        break;
      }
      case 'event': {
        if (message.publishers) {
          break;
        }
        if (message.unpublished) {
          if (message.unpublished === 'ok') {
            this.feed.cleanupConnection();
          } else {
            RTCClient.receivingFeed.unsubscribe(message.unpublished);
          }
        } else if (message.leaving) {
          RTCClient.receivingFeed.unsubscribe(message.leaving);
        } else if (message.configured === 'ok') {
          logger.debug(`Publisher configured`);
        } else if (message.error_code) {
          store.dispatch(videoroomError({ handle: feed.handle, message }));
        } else {
          const messageStr = 'Got an anomalous message in publishing feed';

          logger.remote({ system: true, capture: 'streaming' }).warn(messageStr, message);
        }
        break;
      }
      case 'talking': {
        const feedId = this.feed.handle?.feedId;
        if (feedId === message.id) {
          const localAudioBroadcasting = selectLocalAudioBroadcasting(store.getState());
          if (!localAudioBroadcasting) {
            const errorMsg = `Audio is flowing but is disabled in UI, feed=${feedId}`;
            logger.remote().error(errorMsg);
            Sentry.captureException(new Error(errorMsg));
          }
          SignalingSocket.send({ event: 'startedTalking' });
        }
        break;
      }
      case 'stopped-talking':
        if (this.feed.handle?.feedId === message.id) {
          SignalingSocket.send({ event: 'stoppedTalking' });
        }
        break;
      default:
        logger
          .remote({ system: true, capture: 'streaming' })
          .warn('Got an anomalous message in publishing feed', message);
    }
  };
}
