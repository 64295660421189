import { call, put } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { WhiteboardCreatedPayload } from 'features/content-library/types';
import { displaySimpleNotification } from 'features/notifications/simple-notifications/displaySimpleNotification';
import i18n from 'i18n';

export function* onWhiteboardCreatedSaga(action: PayloadAction<WhiteboardCreatedPayload>) {
  yield call(eventBus.sendMessage, 'whiteboardCreated', undefined, action.payload);

  const notificationThunk: Action = yield call(displaySimpleNotification, {
    content: i18n.t('notifications:content_library.whiteboard_saved'),
  });

  yield put(notificationThunk);
}
