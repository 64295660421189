import { put } from 'redux-saga/effects';
import { embedPlayerStateChanged } from 'features/content-library/contentLibrarySlice';
import { signalingContentLibraryVideoPlayerStateChanged } from 'features/content-library/actions';

export function* onContentLibraryVideoPlayerRemoteStateChangedSaga(
  action: ReturnType<typeof signalingContentLibraryVideoPlayerStateChanged>
) {
  // @TODO calculate custom time if needed
  yield put(
    embedPlayerStateChanged(action.payload, {
      remoteInteraction: true,
    })
  );
}
