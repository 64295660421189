import { apiErrorMessages, APIErrorType } from 'utils/http/index';
import ApiError from 'utils/http/ApiError';
import { formatMessageWithDate } from 'utils/http/format';

export const lookupApiErrorMessage = (error: ApiError) => {
  if (!error.type) {
    return undefined;
  }

  if (error.type === APIErrorType.token_nbf) {
    return {
      description: formatMessageWithDate(error.message),
    };
  }

  return apiErrorMessages[error.type];
};
