import { call, select, put } from 'redux-saga/effects';
import { PermissionTypes } from 'features/permissions/types';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { whiteboardCreated } from 'features/layout/features/content/actions';
import { WhiteboardCreatedOptions } from 'features/layout/features/content/types';
import { eventBus } from 'utils/eventBus';
import { validateCreateWhiteboardOptions } from './utils/validation';

export function* onCreateWhiteboardSaga(options: WhiteboardCreatedOptions) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'whiteboard');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Whiteboard disabled. You’ll need to edit this room’s properties to create whiteboard in this room',
      'whiteboard'
    );

    return;
  }

  const canCreateWhiteboard: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.editWhiteboard
  );

  if (!canCreateWhiteboard) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to create whiteboard',
      'whiteboard'
    );

    return;
  }

  if (typeof options === 'undefined') {
    yield call(eventBus.error, {
      name: 'create-whiteboard',
      message:
        'No options were provided to create the whiteboard. Please supply valid options and try again.',
    });
    return;
  }

  const { isValid, message } = yield call(validateCreateWhiteboardOptions, options);

  if (!isValid) {
    yield call(eventBus.error, {
      name: 'create-whiteboard',
      message,
    });
    return;
  }

  yield put(whiteboardCreated(options));
}
