const imgToDataURL = (image: HTMLImageElement) => {
  try {
    const canvas = document.createElement('canvas');

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    canvas.getContext('2d')?.drawImage(image, 0, 0);

    return canvas.toDataURL();
  } catch {
    return false;
  }
};

export const validateImageURL = async (url: string): Promise<boolean> => {
  const img = new Image();

  img.crossOrigin = 'Anonymous';

  img.src = url;

  const loaderPromise: Promise<boolean> = new Promise((resolve) => {
    img.onerror = () => resolve(false);

    // check if image is embeddable in canvas;
    img.onload = () => resolve(!!imgToDataURL(img));
  });

  const timeoutPromise: Promise<boolean> = new Promise((resolve) => {
    setTimeout(() => resolve(false), 5000);
  });

  return Promise.race([loaderPromise, timeoutPromise]);
};
