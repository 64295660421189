import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import { selectContentWidth } from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import { useDimensionsObserver } from 'features/layout/useDimensionsObserver';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { RoomLayoutValuesProvider } from 'features/layout/Context';
import { styleConstants } from 'theme/styleConstants';

const DimensionsWatcher = ({ children }: { children: React.ReactElement }) => {
  useDimensionsObserver();

  if (!children) {
    return null;
  }

  return children;
};

interface LayoutProviderProps {
  size: { width: number | undefined; height: number | undefined };
  children: React.ReactElement;
}

export const LayoutProvider = ({ size: layoutSize, children }: LayoutProviderProps) => {
  const defaultWidth = window.innerWidth;
  const defaultHeight = window.innerHeight;

  const contentWidth = useAppSelector(selectContentWidth);

  const { isMobile, isPortrait } = useBreakpoints();

  const maximizedStream = useAppSelector(selectMaximizedStream);
  const hasContent = !!maximizedStream;

  const roomWidth = layoutSize.width || defaultWidth;
  const roomHeight = layoutSize.height || defaultHeight;

  const verticalLayout = useMemo(
    () => (isMobile && isPortrait) || roomHeight > roomWidth,
    [isMobile, isPortrait, roomWidth, roomHeight]
  );

  // @FIXME: these calculations could be improved so "vertical layout" is more straightforward. Maybe a separate AppLayout?;
  const tilesWidth = verticalLayout
    ? roomWidth
    : roomWidth - (hasContent ? contentWidth : 0) * roomWidth;

  const tilesHeight =
    verticalLayout && hasContent
      ? roomHeight - roomWidth * 0.75 - styleConstants.layout.innerPadding * 2
      : roomHeight || defaultHeight;

  const contentHeight = verticalLayout ? roomWidth * 0.75 : roomHeight;

  const value = useMemo(
    () => ({
      roomWidth,
      roomHeight,
      contentHeight,
      tilesHeight,
      tilesWidth,
      verticalLayout,
    }),
    [roomWidth, roomHeight, contentHeight, tilesHeight, tilesWidth, verticalLayout]
  );

  return (
    <RoomLayoutValuesProvider value={value}>
      <DimensionsWatcher>{children}</DimensionsWatcher>
    </RoomLayoutValuesProvider>
  );
};
