import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useWhiteboardControl } from 'features/toolbar/Toolbar/controls/whiteboard/useWhiteboardControl';
import ToolbarMenuItem from 'features/toolbar/ToolbarMenuItem/ToolbarMenuItem';
import Icon from 'components/Icon';

const WhiteboardControl = () => {
  const { t } = useTranslation('room');

  const { active, label, createWhiteboard, isFeatureAvailable } = useWhiteboardControl();

  if (!isFeatureAvailable) {
    return null;
  }

  return (
    <ToolbarMenuItem
      menu
      id="whiteboard-control"
      overlay={label}
      ariaLabel={label}
      active={active}
      icon="whiteboard"
      MenuProps={{ PopperProps: { sx: { zIndex: 1600 } } }}
    >
      <MenuItem onClick={createWhiteboard}>
        <ListItemIcon>
          <Icon name="whiteboard-create" />
        </ListItemIcon>
        <ListItemText>{t('toolbar.create_whiteboard')}</ListItemText>
      </MenuItem>
    </ToolbarMenuItem>
  );
};

export default WhiteboardControl;
