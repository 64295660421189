import { call } from 'redux-saga/effects';
import { SignalingSocket } from 'services/signaling';
import { whiteboardCreated } from 'features/layout/features/content/actions';

export function* createWhiteboardSaga(action: ReturnType<typeof whiteboardCreated>) {
  yield call(SignalingSocket.send, {
    event: 'createWhiteboard',
    data: action.payload,
  });
}
