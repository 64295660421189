import { useEffect } from 'react';
import { whiteboardCreated } from 'features/layout/features/content/actions';
import { WhiteboardCreatedOptions } from 'features/layout/features/content/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useWhiteboard } from 'features/whiteboard/useWhiteboard';
import {
  selectActiveFolderId,
  selectActiveLibraryType,
} from 'features/content-library/contentLibrarySlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';

const CreateNewWhiteboardConfirmationModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const { isFeatureAvailable } = useWhiteboard();

  const shouldClose = !isFeatureAvailable;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('createNewWhiteboardConfirmation'));
    }
  }, [shouldClose, dispatch]);

  const activeFolderId = useAppSelector(selectActiveFolderId);
  const activeLibraryType = useAppSelector(selectActiveLibraryType);

  const handleOk = () => {
    const options: WhiteboardCreatedOptions = {
      folderId: activeFolderId === 'root' ? undefined : activeFolderId,
      personal: activeLibraryType === 'personal',
    };

    dispatch(whiteboardCreated(options));
    dispatch(closeModal('createNewWhiteboardConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('createNewWhiteboardConfirmation'));
  };

  return (
    <ConfirmationDialog
      open={!shouldClose}
      maxWidth="sm"
      title={t('create_new_whiteboard_confirmation.title')}
      description={t('create_new_whiteboard_confirmation.description')}
      cancelText={t('create_new_whiteboard_confirmation.btn_cancel_label')}
      okText={t('create_new_whiteboard_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default CreateNewWhiteboardConfirmationModal;
