import Select from 'components/Select';
import { CaptionsSpokenLanguage } from 'features/application/types';
import {
  fontSizeChanged,
  selectCaptionsFontSize,
  selectCaptionsSpokenLanguage,
  spokenLanguageChanged,
} from 'features/transcripts/slices/captions/captionsSlice';
import React from 'react';
import { TabPanelInner } from 'components/Tabs';
import { FormControl, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import InputLabel from 'components/InputLabel';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const languages: { code: CaptionsSpokenLanguage; nested?: boolean }[] = [
  // { code: 'zh' },
  // { code: 'zh-CN', nested: true },
  // { code: 'zh-TW', nested: true },
  // { code: 'da' },
  // { code: 'nl' },
  { code: 'en' },
  // { code: 'en-AU', nested: true },
  // { code: 'en-GB', nested: true },
  // { code: 'en-IN', nested: true },
  // { code: 'en-NZ', nested: true },
  // { code: 'en-US', nested: true },
  // { code: 'fr' },
  // { code: 'fr-CA', nested: true },
  { code: 'de' },
  { code: 'ro' },
  { code: 'ar' },
  // { code: 'hi' },
  // { code: 'hi-Latn', nested: true },
  // { code: 'id' },
  // { code: 'it' },
  // { code: 'ja' },
  // { code: 'ko' },
  // { code: 'no' },
  // { code: 'pl' },
  { code: 'pt' },
  // { code: 'pt-BR', nested: true },
  // { code: 'ru' },
  // { code: 'es' },
  // { code: 'es-419', nested: true },
  // { code: 'sv' },
  // { code: 'ta' },
  // { code: 'tr' },
  // { code: 'uk' },
];

const fontSizes = [16, 24, 32];

const CaptionsTab = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('settings');

  const language = useAppSelector(selectCaptionsSpokenLanguage);
  const fontSize = useAppSelector(selectCaptionsFontSize);

  const handleLanguageChange = (e: SelectChangeEvent) => {
    dispatch(spokenLanguageChanged(e.target.value as CaptionsSpokenLanguage));
  };
  const handleFontSizeChange = (e: SelectChangeEvent) => {
    dispatch(fontSizeChanged(Number(e.target.value)));
  };

  const renderLanguageSelectValue = (value: string) => {
    const [lang, region = null] = value.split('-');
    if (region) {
      return `${t(`captions.languages.${lang}`)} (${t(`captions.languages.${value}`)})`;
    }

    return t(`captions.languages.${lang}`);
  };

  return (
    <TabPanelInner>
      <FormControl fullWidth sx={{ mb: 4 }}>
        <label htmlFor="spoken-language" id="spoken-language-label">
          <Typography>{t('captions.spoken_language_label')}</Typography>
          <Typography
            component="span"
            sx={{ display: 'block', color: 'text.secondary', fontSize: '0.75rem' }}
          >
            {t('captions.spoken_language_help')}
          </Typography>
        </label>
        <Select
          sx={{ mt: '8px !important' }}
          labelId="spoken-language-label"
          id="spoken-language"
          onChange={handleLanguageChange}
          value={language}
          renderValue={renderLanguageSelectValue}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 500,
              },
            },
          }}
        >
          {languages.map(({ code, nested }, i) => (
            <MenuItem key={i} value={code} sx={nested ? { pl: 4 } : { fontWeight: 400 }}>
              {t(`captions.languages.${code}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="font-size-label">{t('captions.font_size_label')}</InputLabel>
        <Select
          labelId="font-size-label"
          id="font_size"
          onChange={handleFontSizeChange}
          value={fontSize}
        >
          {fontSizes.map((code, i) => (
            <MenuItem key={i} value={code}>
              {t(`captions.fontSizes.${code}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </TabPanelInner>
  );
};

export default CaptionsTab;
