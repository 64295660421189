import React, { useState } from 'react';
import MobileMenuItem from 'features/toolbar/Toolbar/mobile/MobileMenuItem';
import { useEndSessionControl } from 'features/toolbar/Toolbar/controls/end-call/useEndSessionControl';
import EndCallDialog from 'features/toolbar/Toolbar/controls/end-call/EndCallDialog';

const EndCallControlMobile = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    icon,
    singleControlLabel,
    menuLabel,
    showNestedControls,
    singleControlHandler,
    availableControls,
  } = useEndSessionControl();

  if (!availableControls.some(Boolean)) {
    return null;
  }

  const handleClick = () => {
    if (showNestedControls) {
      setDialogOpen(true);
    } else {
      singleControlHandler();
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <MobileMenuItem data-testid="end-call-control" icon={icon} onClick={handleClick}>
        {showNestedControls ? menuLabel : singleControlLabel}
      </MobileMenuItem>
      <EndCallDialog open={dialogOpen} onClose={handleClose} />
    </>
  );
};

export default EndCallControlMobile;
