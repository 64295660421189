import dayjs from 'dayjs';

export const formatMessageWithDate = (message: string) => {
  const match = message.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z/);

  if (!match) {
    return message;
  }

  const isoDate = match[0];
  const formattedDate = dayjs(isoDate).format('MMMM D, YYYY');
  const formattedTime = dayjs(isoDate).format('HH:mm:ss [UTC]');

  return `The token will be valid starting ${formattedDate}, at ${formattedTime}. Please try again later.`;
};
