import ReactPlayer from 'react-player';
import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  ContentLibraryVideoPlayer,
  EmbedPlayerType,
  VideoPlayerRemoteInteractionType,
} from 'features/content-library/types';
import {
  embedPlayerRemoteInteractionReset,
  embedPlayerStateChanged,
} from 'features/content-library/contentLibrarySlice';
import { useAppDispatch } from 'store/hooks';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';
import { useInterval } from 'hooks/useInterval';

interface TypedReactPlayer extends ReactPlayer {
  player: Record<string, any>;
}

const Root = styled('div')<{ disableInteraction?: boolean }>(({ disableInteraction }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  '.embed-player': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    ...(disableInteraction && {
      cursor: 'default',
      pointerEvents: 'none',
    }),
  },
}));

export type EmbedPlayerProps = {
  url: string;
  playerState: ContentLibraryVideoPlayer;
  canPresent: boolean;
  playerType: EmbedPlayerType;
};

const NOCOOKIE_HOST = 'https://www.youtube-nocookie.com';

const Player = ({ url, playerState, canPresent, playerType = 'youtube' }: EmbedPlayerProps) => {
  const dispatch = useAppDispatch();

  const [monitorActive, setMonitorActive] = useState(false);

  const readyRef = useRef(false);
  const playerRef = useRef<TypedReactPlayer | null>(null);

  // Workaround for Vimeo player: it starts playing from a seek position but doesn't provide the current time during the initial play event.
  const seekedSecondsRef = useRef(0);

  const { id, state, remoteInteraction, currentTime, volume, muted, skipLocalInteractions } =
    playerState;

  const resetRemoteInteraction = (type: VideoPlayerRemoteInteractionType) => {
    dispatch(
      embedPlayerRemoteInteractionReset({
        id,
        type,
      })
    );
  };

  const monitorPlayerState = async () => {
    const internalPlayer = playerRef.current?.getInternalPlayer();
    if (!internalPlayer) {
      return;
    }

    const [currentVolume, currentMuted] =
      playerType === 'vimeo'
        ? [await internalPlayer.getVolume(), await internalPlayer.getMuted()]
        : [internalPlayer.getVolume() / 100, internalPlayer.isMuted()];

    const time = playerRef.current?.getCurrentTime() ?? 0;

    if (volume !== currentVolume) {
      // volume has been changed, don't dispatch for remote updates
      if (!skipLocalInteractions.volume) {
        dispatch(
          embedPlayerStateChanged({
            id,
            currentTime: time,
            type: 'volume',
            volume: currentVolume,
          })
        );
      } else if (skipLocalInteractions.volume) {
        resetRemoteInteraction('volume');
      }
    }

    if (muted !== currentMuted) {
      // mute has been changed, don't dispatch for remote updates
      if (!skipLocalInteractions.mute) {
        dispatch(
          embedPlayerStateChanged({
            id,
            currentTime: time,
            type: 'mute',
            muted: currentMuted,
          })
        );
      } else if (skipLocalInteractions.mute) {
        resetRemoteInteraction('mute');
      }
    }
  };

  const shouldSyncSeek = skipLocalInteractions.playback;

  useEffect(() => {
    if (playerRef.current && readyRef.current && shouldSyncSeek) {
      if (state === 'playing') {
        // console.log('seeking', currentTime);
        playerRef.current.seekTo(currentTime);
      }
    }
  }, [currentTime, shouldSyncSeek, state]);

  useInterval(
    () => {
      monitorPlayerState();
    },
    monitorActive && canPresent ? 1000 : null
  );

  const handlePlay = () => {
    // console.log(
    //   'onPlay',
    //   playerRef.current?.getCurrentTime(),
    //   remoteInteraction ? 'remote' : 'local'
    // );
    if (!skipLocalInteractions.playback) {
      const time = playerRef.current?.getCurrentTime() ?? seekedSecondsRef.current ?? 0;
      dispatch(
        embedPlayerStateChanged({
          id,
          state: 'playing',
          currentTime: time,
          type: 'playback',
        })
      );
    } else {
      resetRemoteInteraction('playback');
    }
  };

  const handlePause = () => {
    // console.log(
    //   'onPause',
    //   playerRef.current?.getCurrentTime(),
    //   remoteInteraction ? 'remote' : 'local'
    // );
    if (!skipLocalInteractions.playback) {
      dispatch(
        embedPlayerStateChanged({
          id,
          state: 'paused',
          type: 'playback',
          currentTime: playerRef.current?.getCurrentTime() ?? 0,
        })
      );
    } else {
      resetRemoteInteraction('playback');
    }
  };

  const handleEnded = () => {
    if (!remoteInteraction) {
      dispatch(
        embedPlayerStateChanged({
          id,
          state: 'ended',
          type: 'playback',
        })
      );
    } else {
      dispatch(
        embedPlayerRemoteInteractionReset({
          id,
          type: 'playback',
        })
      );
    }
  };

  // this event is not available in youtube player
  const handleSeek = (seconds: number) => {
    seekedSecondsRef.current = seconds;
  };

  const handleError = (error: any) => {
    Sentry.captureException(error);
    logger.remote({ tier: 1 }).error('Embed player error', error);
  };

  const handleReady = () => {
    // console.log('Embed player ready');

    const player = playerRef.current;
    if (player) {
      if (muted) {
        player.player.player.mute();
      } else {
        player.player.player.unmute();
      }
    }

    readyRef.current = true;
    setMonitorActive(true);
  };

  return (
    <Root disableInteraction={!canPresent}>
      <ReactPlayer
        className="embed-player"
        width="100%"
        height="100%"
        ref={playerRef}
        url={url}
        playsinline
        controls
        muted={muted}
        // @ts-ignore
        volume={volume}
        playing={state === 'playing'}
        onReady={handleReady}
        onSeek={handleSeek}
        onEnded={handleEnded}
        onPlay={handlePlay}
        onPause={handlePause}
        onError={handleError}
        config={{
          youtube: {
            playerVars: {
              disablekb: 1,
              start: playerState.currentTime,
            },
            embedOptions: {
              host: NOCOOKIE_HOST,
            },
          },
          vimeo: {
            playerOptions: {
              start_time: playerState.currentTime,
            },
          },
        }}
      />
    </Root>
  );
};

export default Player;
