import camelCase from 'camelcase';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import { FeatureFlagType, FeaturesState } from 'features/feature-set/types';
import { signalingRoomJoined } from 'features/room/actions';
import { signalingFeatureDisabled, signalingFeatureEnabled } from 'features/feature-set/actions';
import camelcaseKeys from 'camelcase-keys';

export const initialState: FeaturesState = {
  initialized: false,
  flags: {
    generalChat: false,
    privateGroupChat: false,
    privateChat: false,
    contentLibrary: false,
    captions: false,
    qa: false,
    endSession: false,
    leaveSession: false,
    fullScreen: false,
    languageSelection: false,
    minimizeOwnTile: false,
    participantsList: false,
    pin: false,
    raiseHand: true,
    recordings: false,
    screenshare: false,
    virtualBackgrounds: false,
    whiteboard: false,
    invite: false,
    breakoutRooms: false,
    filesPanel: false,
    pictureInPicture: false,
    polls: false,
  },
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    featureEnabled: (state, action: PayloadAction<FeatureFlagType>) => {
      state.flags[action.payload] = true;
    },
    featureDisabled: (state, action: PayloadAction<FeatureFlagType>) => {
      state.flags[action.payload] = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorizeUser.fulfilled, (state, action) => {
        Object.assign(state.flags, action.payload.features);
      })
      .addCase(signalingRoomJoined, (state, action) => {
        Object.assign(state.flags, camelcaseKeys(action.payload.dynamicFeatures));

        state.initialized = true;
      })
      .addCase(signalingFeatureEnabled, (state, action) => {
        const convertedFlag = camelCase(action.payload.feature) as unknown as FeatureFlagType;
        state.flags[convertedFlag] = true;
      })
      .addCase(signalingFeatureDisabled, (state, action) => {
        const convertedFlag = camelCase(action.payload.feature) as unknown as FeatureFlagType;
        state.flags[convertedFlag] = false;
      });
  },
});

export const { featureEnabled, featureDisabled } = featuresSlice.actions;

export const selectFeaturesInitialized = (state: RootState) => state.features.initialized;

export const selectFeatureFlags = (state: RootState) => state.features.flags;

export const selectFeatureFlag = (state: RootState, featureFlag: FeatureFlagType) =>
  state.features.flags[featureFlag];

export default featuresSlice.reducer;
