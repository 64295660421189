import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import {
  whiteboardClosed,
  whiteboardCreated,
  whiteboardOpened,
} from 'features/layout/features/content/actions';
import { WhiteboardCreatedOptions } from 'features/layout/features/content/types';
import { openModal } from 'features/modal/modalSlice';
import { selectWhiteboardId, selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { useWhiteboard } from 'features/whiteboard/useWhiteboard';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import {
  selectActiveFolderId,
  selectActiveLibraryType,
} from 'features/content-library/contentLibrarySlice';

export const useWhiteboardControl = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('room');

  const whiteboardOpen = useAppSelector(selectWhiteboardOpen);
  const currentWhiteboardId = useAppSelector(selectWhiteboardId);

  const e2eeEnabled = useAppSelector(selectE2eeEnabled);

  const activeFolderId = useAppSelector(selectActiveFolderId);
  const activeLibraryType = useAppSelector(selectActiveLibraryType);

  const { hasPermissions } = usePermissions();

  const { isFeatureAvailable } = useWhiteboard();

  const isContentLibraryEnabled = useFeatureFlag('contentLibrary');
  const canPresentContentLibrary = hasPermissions(PermissionTypes.presentContentLibrary);

  const isContentLibraryFeatureAvailable = isContentLibraryEnabled && canPresentContentLibrary;

  const label = t('toolbar.create_whiteboard');

  const openWhiteboard = (id: string) => {
    if (!whiteboardOpen || currentWhiteboardId !== id) {
      if (e2eeEnabled) {
        dispatch(openModal('whiteboardE2eeConfirmation', { whiteboardId: id }));
      } else {
        dispatch(whiteboardOpened(id));
      }
    }
  };

  const closeWhiteboard = (id: string) => {
    if (whiteboardOpen) {
      if (!isContentLibraryFeatureAvailable) {
        dispatch(openModal('closeWhiteboardConfirmation'));
      } else {
        dispatch(whiteboardClosed(id));
      }
    }
  };

  const createWhiteboard = () => {
    const options: WhiteboardCreatedOptions = {
      folderId: activeFolderId === 'root' ? undefined : activeFolderId,
      personal: activeLibraryType === 'personal',
    };

    if (!isContentLibraryFeatureAvailable && whiteboardOpen) {
      dispatch(openModal('createNewWhiteboardConfirmation'));
    } else {
      dispatch(whiteboardCreated(options));
    }
  };

  return {
    active: whiteboardOpen,
    label,
    openWhiteboard,
    closeWhiteboard,
    createWhiteboard,
    isFeatureAvailable,
  };
};
