import React from 'react';
import { styled } from '@mui/material/styles';

export type RatioProps = React.ComponentPropsWithoutRef<'div'> & {
  children: React.ReactChild;
  ratio?: number;
};

function toPercent(num: number) {
  if (num <= 0 || num > 100) {
    return '100%';
  }
  if (num < 1) {
    return `${num * 100}%`;
  }
  return `${num}%`;
}

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'ratio',
})<RatioProps>(({ ratio = 100 }) => ({
  width: '100%',
  position: 'relative',
  '&::before': {
    display: 'block',
    paddingTop: toPercent(ratio),
    content: '""',
  },
  '> *': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}));

const AspectRatio = ({ children, ratio = 9 / 16 }: RatioProps) => (
  <Root ratio={ratio}>{React.Children.only(children)}</Root>
);

export default AspectRatio;
