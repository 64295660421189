import { useEffect } from 'react';
import { whiteboardClosed } from 'features/layout/features/content/actions';
import { useWhiteboard } from 'features/whiteboard/useWhiteboard';
import { selectWhiteboardId } from 'features/whiteboard/whiteboardSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'components/ConfirmationDialog';

const CloseWhiteboardConfirmationModal = () => {
  const { t } = useTranslation('modals');
  const dispatch = useAppDispatch();

  const currentWhiteboardId = useAppSelector(selectWhiteboardId);

  const { isFeatureAvailable } = useWhiteboard();

  const shouldClose = !isFeatureAvailable;

  useEffect(() => {
    if (shouldClose) {
      dispatch(closeModal('closeWhiteboardConfirmation'));
    }
  }, [shouldClose, dispatch]);

  const handleOk = () => {
    if (currentWhiteboardId) {
      dispatch(whiteboardClosed(currentWhiteboardId));
    }
    dispatch(closeModal('closeWhiteboardConfirmation'));
  };

  const handleCancel = () => {
    dispatch(closeModal('closeWhiteboardConfirmation'));
  };

  return (
    <ConfirmationDialog
      open={!shouldClose}
      maxWidth="sm"
      title={t('close_whiteboard_confirmation.title')}
      description={t('close_whiteboard_confirmation.description')}
      cancelText={t('close_whiteboard_confirmation.btn_cancel_label')}
      okText={t('close_whiteboard_confirmation.btn_ok_label')}
      onOk={handleOk}
      onCancel={handleCancel}
      preventClose
      hideCloseButton
    />
  );
};

export default CloseWhiteboardConfirmationModal;
