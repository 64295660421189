import { selectMaximizedContent } from 'features/layout/features/content/contentSlice';
import { WhiteboardLoader } from 'features/whiteboard/WhiteboardLoader';
import {
  selectShowWhiteboardLoader,
  selectWhiteboardId,
} from 'features/whiteboard/whiteboardSlice';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { lazy, Suspense } from 'react';
import { useAppSelector } from 'store/hooks';

const Whiteboard = lazy(() => import('./Whiteboard/Whiteboard'));

export const WhiteboardPanel = () => {
  const showLoader = useAppSelector(selectShowWhiteboardLoader);

  const maximizedContent = useAppSelector(selectMaximizedContent);
  const whiteboardId = useAppSelector(selectWhiteboardId);

  const { isMobile } = useBreakpoints();

  const maximizeWB = maximizedContent === 'whiteboard' || isMobile;

  return (
    <>
      <Suspense fallback={null}>
        <Whiteboard key={whiteboardId} dragHandlePadding={!maximizeWB} />
      </Suspense>
      {showLoader ? <WhiteboardLoader /> : null}
    </>
  );
};
