import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { whiteboardClosed } from 'features/layout/features/content/actions';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { selectWhiteboardId } from 'features/whiteboard/whiteboardSlice';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelCloseControl } from 'features/layout/GridPanel/GridPanelCloseControl';
import { openModal } from 'features/modal/modalSlice';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const WhiteboardCloseControl = () => {
  const dispatch = useAppDispatch();
  const isRecorder = useRecorder();

  const currentWhiteboardId = useAppSelector(selectWhiteboardId);

  const { hasPermissions } = usePermissions();

  const canEditWhiteboard = hasPermissions(PermissionTypes.editWhiteboard);

  const isFeatureEnabled = useFeatureFlag('whiteboard');
  const isContentLibraryEnabled = useFeatureFlag('contentLibrary');

  if (!(isFeatureEnabled && canEditWhiteboard) || isRecorder) {
    return null;
  }

  const canPresentContentLibrary = hasPermissions(PermissionTypes.presentContentLibrary);
  const isContentLibraryFeatureAvailable = isContentLibraryEnabled && canPresentContentLibrary;

  const handleClick = () => {
    if (currentWhiteboardId) {
      if (!isContentLibraryFeatureAvailable) {
        dispatch(openModal('closeWhiteboardConfirmation'));
      } else {
        dispatch(whiteboardClosed(currentWhiteboardId));
      }
    }
  };

  return <GridPanelCloseControl icon="close" onClick={handleClick} />;
};
