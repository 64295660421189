import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  getContentLibrary,
  normalizeContentLibrary,
  normalizeFile,
  normalizeFolder,
} from 'features/content-library/utils/normalizeContentLibrary';
import { handleFileProcessing } from 'features/content-library/utils/handleFileProcessing';
import {
  signalingContentLibraryConversionFailed,
  signalingContentLibraryConversionProgress,
  signalingContentLibraryEmbedContentCreated,
  signalingContentLibraryFileClosed,
  signalingContentLibraryFileConverted,
  signalingContentLibraryFileDeleted,
  signalingContentLibraryFilePageChanged,
  signalingContentLibraryFileRenamed,
  signalingContentLibraryFileUploadAccepted,
  signalingContentLibraryFileUploaded,
  signalingContentLibraryFolderCreated,
  signalingContentLibraryFolderDeleted,
  signalingContentLibraryFolderRenamed,
  signalingLibraryReceived,
} from 'features/content-library/actions';
import { authorizeUser } from 'features/room/thunks/authorizeUser';
import {
  signalingWhiteboardClosed,
  signalingWhiteboardCreated,
  signalingWhiteboardOpened,
} from 'features/layout/features/content/actions';
import {
  ContentLibraryFileEntry,
  ContentLibraryFileProcessingMeta,
  ContentLibraryFolder,
  ContentLibraryOpenedFile,
  ContentLibraryState,
  ContentLibraryType,
  ContentLibraryVideoPlayer,
  ContentLibraryVideoPlayerStateChangePayload,
  ContentLibraryTemplateParams,
  VideoPlayerRemoteInteractionType,
} from './types';
import { formatUrlParams, replaceUrlParams } from './utils/urlParams';

const openedFileInitialState = {
  data: null,
  currentPage: 0,
};

export const libraryInitialState = {
  libraryId: '',
  activeFolderId: 'root',
  folders: {},
  files: {},
  fileProcessingMeta: {},
};

export const initialState: ContentLibraryState = {
  openedFile: {
    ...openedFileInitialState,
  },
  openedFileThumbnailsActive: false,
  activeLibraryType: 'room',
  libraries: {
    personal: {
      ...libraryInitialState,
    },
    room: {
      ...libraryInitialState,
    },
  },
  videoPlayers: {},
  templateParams: {},
};

export const contentLibrary = createSlice({
  name: 'contentLibrary',
  initialState,
  reducers: {
    folderOpened(state, action: PayloadAction<string>) {
      state.libraries[state.activeLibraryType].activeFolderId = action.payload;
    },
    fileUploadProgressChanged(state, action: PayloadAction<{ id: string; progress: number }>) {
      const meta = state.libraries[state.activeLibraryType].fileProcessingMeta[action.payload.id];
      if (meta) {
        meta.progress = action.payload.progress;
      }
    },
    fileOpened(state, action: PayloadAction<ContentLibraryOpenedFile>) {
      state.openedFile = {
        data: action.payload,
        currentPage: 0,
      };

      if ('pages' in action.payload) {
        state.openedFile.currentPage = action.payload.page ?? 0;
      }

      if (state.openedFile.data && action.payload.type === 'webapp') {
        state.openedFile.data.url = encodeURI(
          replaceUrlParams(formatUrlParams(action.payload.url, 'decode'), state.templateParams)
        );
      }

      // @TODO shall we move it on the saga level and dispatch separately?
      if (action.payload.type === 'youtube' || action.payload.type === 'vimeo') {
        const player: ContentLibraryVideoPlayer = {
          id: action.payload.id,
          isReady: false,
          state: 'idle',
          currentTime: 0,
          remoteInteraction: false,
          skipLocalInteractions: {},
          muted: false,
          volume: null,
        };

        if ('videoPlayerState' in action.payload) {
          const { videoPlayerState } = action.payload;
          Object.assign(player, videoPlayerState);
          if (player.state === 'playing') {
            player.remoteInteraction = true;
            player.skipLocalInteractions.playback = true;
          }
        }

        state.videoPlayers[action.payload.id] = player;
      }
    },
    fileClosed(state) {
      state.openedFile = {
        ...openedFileInitialState,
      };

      // @TODO cleanup video player
    },
    fileThumbnailsToggled(state) {
      state.openedFileThumbnailsActive = !state.openedFileThumbnailsActive;
    },
    fileUploadFailed(state, action: PayloadAction<{ id: string }>) {
      const processingMeta =
        state.libraries[state.activeLibraryType].fileProcessingMeta[action.payload.id];
      if (processingMeta) {
        processingMeta.status = 'uploading-failed';
      }
    },
    embedPlayerStateChanged: {
      reducer: (
        state,
        action: PayloadAction<
          ContentLibraryVideoPlayerStateChangePayload,
          string,
          { remoteInteraction?: boolean }
        >
      ) => {
        const player = state.videoPlayers[action.payload.id];
        if (player) {
          const {
            meta,
            payload: { type, ...payload },
          } = action;
          if (meta.remoteInteraction) {
            player.remoteInteraction = true;
            player.skipLocalInteractions[type] = true;
          }

          state.videoPlayers[payload.id] = {
            ...player,
            ...payload,
          };
        }
      },
      prepare: (
        payload: ContentLibraryVideoPlayerStateChangePayload,
        meta: { remoteInteraction?: boolean } = {}
      ) => ({
        payload,
        meta,
      }),
    },
    embedPlayerRemoteInteractionReset(
      state,
      action: PayloadAction<{ id: string; type: VideoPlayerRemoteInteractionType }>
    ) {
      const player = state.videoPlayers[action.payload.id];
      if (player) {
        player.remoteInteraction = false;
        delete player.skipLocalInteractions[action.payload.type];
      }
    },
    embedPlayerReady(
      state,
      action: PayloadAction<{
        id: string;
      }>
    ) {
      const player = state.videoPlayers[action.payload.id];
      if (player) {
        player.isReady = true;
      }
    },
    activeLibraryChanged(state, action: PayloadAction<ContentLibraryType>) {
      state.activeLibraryType = action.payload;
    },
    templateParamsChanged(state, action: PayloadAction<ContentLibraryTemplateParams>) {
      state.templateParams = {
        ...state.templateParams,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signalingLibraryReceived, (state, action) => {
        const libraryType: ContentLibraryType = action.payload.personal ? 'personal' : 'room';
        if (action.payload.id === null) {
          state.libraries[libraryType] = { ...libraryInitialState };

          return state;
        }

        const { folders, files, fileProcessingMeta } = normalizeContentLibrary(
          state,
          action.payload
        );

        state.libraries[libraryType].folders = folders;
        state.libraries[libraryType].files = files;
        state.libraries[libraryType].libraryId = action.payload.id;
        state.libraries[libraryType].fileProcessingMeta = fileProcessingMeta;
      })
      .addCase(signalingContentLibraryFolderCreated, (state, action) => {
        const library = getContentLibrary(state, action.payload.personal);
        const folder = normalizeFolder(action.payload);

        const { folders } = library;
        folders[folder.id] = folder;
        if (folders[folder.parentId]) {
          folders[folder.parentId].childFolderIds.push(folder.id);
        }
      })
      .addCase(signalingContentLibraryFolderRenamed, (state, action) => {
        const library = getContentLibrary(state, action.payload.personal);
        const folder = library.folders[action.payload.id];
        if (folder) {
          folder.name = action.payload.name;
        }
      })
      .addCase(signalingContentLibraryFileRenamed, (state, action) => {
        const library = getContentLibrary(state, action.payload.personal);
        const file = library.files[action.payload.id];
        if (file) {
          file.name = action.payload.name;
        }
        if (state.openedFile?.data?.name) {
          state.openedFile.data.name = action.payload.name;
        }
      })
      .addCase(signalingContentLibraryFileDeleted, (state, action) => {
        const { id: fileId, personal } = action.payload;
        const library = getContentLibrary(state, personal);
        const file = library.files[fileId];

        if (file) {
          // Remove the file from its parent folder's childFileIds
          const parentFolder = library.folders[file.folderId];
          if (parentFolder) {
            parentFolder.childFileIds = parentFolder.childFileIds.filter((id) => id !== fileId);
          }

          delete library.files[fileId];
        }

        // remove file processing meta
        const meta = library.fileProcessingMeta[fileId];
        if (meta) {
          const parentFolderId = meta.folderId;
          const parentFolder = library.folders[parentFolderId];
          if (parentFolder) {
            parentFolder.childUploadIds = parentFolder.childUploadIds.filter((id) => id !== fileId);
          }

          delete library.fileProcessingMeta[fileId];
        }
      })
      .addCase(signalingContentLibraryFolderDeleted, (state, action) => {
        const { id: deletedFolderId, personal } = action.payload;
        const library = getContentLibrary(state, personal);

        const deletedFolder = library.folders[deletedFolderId];

        if (deletedFolder) {
          // Remove the folder from its parent's childFolderIds
          const parentFolder = library.folders[deletedFolder.parentId];
          if (parentFolder) {
            parentFolder.childFolderIds = parentFolder.childFolderIds.filter(
              (id) => id !== deletedFolderId
            );
          }

          delete library.folders[deletedFolderId];

          // If the deleted folder was the active folder, set the parent folder as the new active folder
          if (deletedFolderId === library.activeFolderId) {
            library.activeFolderId = deletedFolder.parentId || 'root';
          }
        }
      })
      .addCase(signalingContentLibraryFileUploadAccepted, (state, action) => {
        const { id, name, folderId } = action.payload;
        const parentFolder = folderId || 'root';

        const library = state.libraries[state.activeLibraryType];

        library.fileProcessingMeta[action.payload.id] = {
          id,
          name,
          status: 'uploading',
          progress: 0,
          folderId: parentFolder,
          personal: state.activeLibraryType === 'personal',
        };
        if (library.folders[parentFolder]) {
          library.folders[parentFolder].childUploadIds.push(id);
        }
      })
      .addCase(signalingContentLibraryFileUploaded, (state, action) => {
        handleFileProcessing(state, action);
      })
      .addCase(signalingWhiteboardCreated, (state, action) => {
        handleFileProcessing(state, action);
      })
      .addCase(signalingWhiteboardOpened, (state, action) => {
        state.openedFile = {
          data: {
            url: '',
            type: 'whiteboard',
            ...action.payload,
          },
          currentPage: 0,
        };
      })
      .addCase(signalingWhiteboardClosed, (state) => {
        state.openedFile = {
          ...openedFileInitialState,
        };
      })
      .addCase(signalingContentLibraryEmbedContentCreated, (state, action) => {
        const { personal } = action.payload;
        const library = getContentLibrary(state, personal);
        const file = normalizeFile(action.payload);
        library.files[file.id] = file;

        const parentFolder = library.folders[file.folderId];
        if (parentFolder) {
          parentFolder.childFileIds.push(file.id);
        }
      })
      .addCase(signalingContentLibraryFileConverted, (state, action) => {
        const { id, personal } = action.payload;
        const library = getContentLibrary(state, personal);
        const file = library.files[id];
        if (file) {
          file.status = 'completed';
        }

        const processingMeta = library.fileProcessingMeta[id];
        if (processingMeta) {
          delete library.fileProcessingMeta[id];
        }
      })
      .addCase(signalingContentLibraryConversionFailed, (state, action) => {
        const library = getContentLibrary(state, action.payload.personal);
        const processingMeta = library.fileProcessingMeta[action.payload.id];
        if (processingMeta) {
          processingMeta.status = 'conversion-failed';
        }
      })
      .addCase(signalingContentLibraryConversionProgress, (state, action) => {
        const { id, percentage, personal } = action.payload;
        const library = getContentLibrary(state, personal);
        const processingMeta = library.fileProcessingMeta[id];
        if (processingMeta && percentage > processingMeta.progress) {
          processingMeta.progress = percentage;
        }
      })
      .addCase(signalingContentLibraryFilePageChanged, (state, action) => {
        const { data } = action.payload;
        if (state.openedFile.data) {
          if ('pages' in state.openedFile.data) {
            state.openedFile.data.page = data.page;
          } else {
            state.openedFile.data = data;
          }
        }

        state.openedFile.currentPage = data.page;
      })
      .addCase(signalingContentLibraryFileClosed, (state) => {
        state.openedFile = {
          ...openedFileInitialState,
        };
      })
      .addCase(authorizeUser.fulfilled, (state, action) => {
        const { name: username } = action.meta;

        state.templateParams = {
          ...state.templateParams,
          USERNAME: username,
        };
      });
  },
});

export const {
  folderOpened,
  fileUploadProgressChanged,
  fileOpened,
  fileClosed,
  fileThumbnailsToggled,
  fileUploadFailed,
  embedPlayerStateChanged,
  embedPlayerRemoteInteractionReset,
  embedPlayerReady,
  activeLibraryChanged,
  templateParamsChanged,
} = contentLibrary.actions;

export default contentLibrary.reducer;

export const selectTemplateParams = (state: RootState) => state.contentLibrary.templateParams;

export const selectActiveLibraryType = (state: RootState) => state.contentLibrary.activeLibraryType;
export const selectActiveLibrary = (state: RootState) =>
  state.contentLibrary.libraries[state.contentLibrary.activeLibraryType];

export const selectFiles = (state: RootState) => {
  const library = selectActiveLibrary(state);

  return library.files;
};
export const selectFolders = (state: RootState) => {
  const library = selectActiveLibrary(state);

  return library.folders;
};

export const selectFolder = (state: RootState, id: string): ContentLibraryFolder | undefined => {
  const library = selectActiveLibrary(state);

  return library.folders[id];
};

export const selectFile = (state: RootState, id: string): ContentLibraryFileEntry | undefined => {
  const library = selectActiveLibrary(state);

  return library.files[id];
};

export const selectFileByLibraryType = (
  state: RootState,
  id: string,
  libraryType: ContentLibraryType
): ContentLibraryFileEntry | undefined => {
  const library = state.contentLibrary.libraries[libraryType];

  return library.files[id];
};

export const selectActiveFolderId = (state: RootState) => {
  const library = selectActiveLibrary(state);

  return library.activeFolderId;
};

export const selectContentLibraryProcessingMeta = (state: RootState) => {
  const library = selectActiveLibrary(state);

  return library.fileProcessingMeta;
};

export const selectActiveFolder = createSelector(
  [selectActiveFolderId, selectFolders],
  (activeFolderId, folders) => folders[activeFolderId]
);

export const selectContentLibraryHasContent = createSelector(
  [(state) => selectFolder(state, 'root')],
  (rootFolder) => {
    if (!rootFolder) {
      return false;
    }

    // @TODO make it more intuitive?
    return (
      rootFolder.childFolderIds.length > 0 ||
      rootFolder.childFileIds.length > 0 ||
      rootFolder.childUploadIds.length > 0
    );
  }
);

export const selectContentLibraryFileProcessingMeta = (
  state: RootState,
  id: string
): ContentLibraryFileProcessingMeta | undefined => {
  const library = selectActiveLibrary(state);

  return library.fileProcessingMeta[id];
};

export const selectContentLibraryOpenedFile = (state: RootState) =>
  state.contentLibrary.openedFile.data;

export const selectContentLibraryOpenedFileCurrentPage = (state: RootState) =>
  state.contentLibrary.openedFile.currentPage;

export const selectContentLibraryOpenedFileThumbnailsActive = (state: RootState) =>
  state.contentLibrary.openedFileThumbnailsActive;

// returns an ordered array of folder IDs representing the path from the root folder to the active folder, enabling hierarchical navigation in the content library
export const selectContentLibraryFolderStack = createSelector(
  selectFolders,
  selectActiveFolderId,
  (folders, activeFolderId) => {
    const stack = [];
    let currentFolderId = activeFolderId;
    while (currentFolderId) {
      stack.unshift(currentFolderId);
      currentFolderId = folders[currentFolderId]?.parentId;
    }

    return stack;
  }
);

export const selectContentLibraryVideoPlayer = (state: RootState, id: string) => {
  const player = state.contentLibrary.videoPlayers[id];
  if (!player) {
    return;
  }

  return player;
};
