import { call, select, put } from 'redux-saga/effects';
import { PermissionTypes } from 'features/permissions/types';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { selectWhiteboardId, selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { ContentLibraryType } from 'features/content-library/types';
import { eventBus } from 'utils/eventBus';
import {
  whiteboardClosed,
  whiteboardCreated,
  whiteboardOpened,
} from 'features/layout/features/content/actions';
import {
  selectActiveFolderId,
  selectActiveLibraryType,
} from 'features/content-library/contentLibrarySlice';

export function* onToggleWhiteboardSaga(show?: boolean, data?: { id: string }) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'whiteboard');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Whiteboard disabled. You’ll need to edit this room’s properties to use whiteboard in this room',
      'whiteboard'
    );

    return;
  }

  const canToggleWhiteboard: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.editWhiteboard
  );

  if (!canToggleWhiteboard) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to control whiteboard',
      'whiteboard'
    );

    return;
  }

  const whiteboardOpen: boolean = yield select(selectWhiteboardOpen);

  show = typeof show === 'undefined' ? !whiteboardOpen : show;

  if (show) {
    if (data?.id) {
      yield put(whiteboardOpened(data.id));
    } else {
      const activeFolderId: string = yield select(selectActiveFolderId);
      const activeLibraryType: ContentLibraryType = yield select(selectActiveLibraryType);

      yield put(
        whiteboardCreated({
          folderId: activeFolderId === 'root' ? undefined : activeFolderId,
          personal: activeLibraryType === 'personal',
        })
      );
    }
  } else {
    const whiteboardId: string = yield select(selectWhiteboardId);

    yield put(whiteboardClosed(whiteboardId));
  }
}
