import { getState } from 'store/store';
import { selectVideoSettings } from 'features/user-media/userMediaSlice';

const resolutions: Record<number, number> = {
  180: 320,
  360: 640,
  540: 960,
  720: 1280,
  1080: 1920,
};

const aspectRatio = 1.77778;

export const getDefaultVideoConstraints = (): MediaTrackConstraints => {
  const videoSettings = selectVideoSettings(getState());

  const videoHeight = videoSettings.resolution;
  const videoWidth = resolutions[videoHeight] || Math.round(videoHeight * aspectRatio);

  return {
    width: { ideal: videoWidth },
    height: { ideal: videoHeight },
    advanced: [
      { frameRate: { min: 24 } },
      { width: { min: videoWidth } },
      { height: { min: videoHeight } },
      { frameRate: { max: 24 } },
      { width: { max: videoWidth } },
      { height: { max: videoHeight } },
      { aspectRatio: { exact: aspectRatio } },
    ],
  };
};
