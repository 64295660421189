import { useAppDispatch } from 'store/hooks';
import { closeModal } from 'features/modal/modalSlice';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle } from 'components/Dialog';
import { DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { DialogActions } from '../../../components/Dialog';

const WhiteboardAlreadyOpenedModal = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation('modals');

  const hideModal = () => dispatch(closeModal('whiteboardAlreadyOpened'));

  return (
    <Dialog
      open
      aria-labelledby="recording-failure-title"
      aria-describedby="recording-failure-description"
      onClose={hideModal}
      maxWidth="xs"
    >
      <DialogTitle id="recording-failure-title" onClose={hideModal}>
        {t('whiteboard_already_opened.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="recording-failure-description">
          {t('whiteboard_already_opened.description')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hideModal}>{t('whiteboard_already_opened.btn_ok_label')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WhiteboardAlreadyOpenedModal;
