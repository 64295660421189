import { selectVirtualBackgroundStatus } from 'features/virtual-backgrounds/selectors';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { eventBus } from 'utils/eventBus';
import {
  virtualBackgroundChangeStarted,
  virtualBackgroundFailure,
  virtualBackgroundChangeFulfilled,
} from 'features/virtual-backgrounds/virtualBackgroundsSlice';
import { VirtualBackgroundsStatus } from 'features/virtual-backgrounds/types';
import { RoomStatus } from 'features/room/types';
import { selectRoomStatus } from 'features/room/roomSlice';

export function* resetVirtualBackgroundSaga() {
  const roomStatus: RoomStatus = yield select(selectRoomStatus);

  eventBus.sendMessage('virtualBackgroundDisabled', {});

  if (roomStatus !== 'joined') {
    // skip this saga for call outside the room
    return;
  }

  const status: VirtualBackgroundsStatus = yield select(selectVirtualBackgroundStatus);
  if (status === 'updating') {
    return;
  }

  yield put(virtualBackgroundChangeStarted());

  try {
    yield call(RTCClient.publishingFeed.resetVirtualBackground);
    yield put(virtualBackgroundChangeFulfilled());
  } catch (error) {
    yield put(virtualBackgroundFailure());
  }
}
