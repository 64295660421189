import { call, select } from 'redux-saga/effects';
import { base64ToFile, urlToFile } from 'features/sdk/utils/format';
import { validateAddImageToWhiteboardOptions } from 'features/sdk/utils/validation';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { selectWhiteboardOpen } from 'features/whiteboard/whiteboardSlice';
import { board } from 'utils/whiteboard/BoardStateManager';
import { eventBus } from 'utils/eventBus';
import { isFileUnderMaxSize } from 'utils/validation/file';

export type AddImageToWhiteboardOptions = {
  base64?: string;
  url?: string;
  name?: string;
  position?: {
    x: number;
    y: number;
  };
};

export function* onAddImageToWhiteboardSaga(options?: AddImageToWhiteboardOptions) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'whiteboard');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Whiteboard disabled. You’ll need to edit this room’s properties to use whiteboard in this room',
      'whiteboard'
    );

    return;
  }

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (!canEditWhiteboard) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to edit whiteboard',
      'whiteboard'
    );

    return;
  }

  const whiteboardOpen: boolean = yield select(selectWhiteboardOpen);

  if (!whiteboardOpen) {
    yield call(eventBus.error, {
      name: 'add-image-to-whiteboard',
      message: 'The whiteboard is not open. Please open the whiteboard to proceed.',
    });

    return;
  }

  if (typeof options === 'undefined') {
    yield call(eventBus.error, {
      name: 'add-image-to-whiteboard',
      message:
        'No options were provided for adding an image to the whiteboard. Please supply valid options and try again.',
    });
    return;
  }

  const { isValid, message } = yield call(validateAddImageToWhiteboardOptions, options);

  if (!isValid) {
    yield call(eventBus.error, {
      name: 'add-image-to-whiteboard',
      message,
    });
    return;
  }

  const file: File = yield call(
    options.base64 ? base64ToFile : urlToFile,
    options.base64 ? options.base64 : options.url,
    options.name
  );

  if (file) {
    const isValidSize: boolean = yield call(isFileUnderMaxSize, file, 3);

    if (!isValidSize) {
      yield call(eventBus.error, {
        name: 'add-image-to-whiteboard',
        message:
          'The provided image exceeds the maximum allowed size of 3 MB. Please choose a smaller image and try again.',
      });
      return;
    }

    yield call(board.handleImageUpload, {
      file,
      fromSdk: true,
      position: options.position,
    });

    yield call(eventBus.sendMessage, 'imageToWhiteboardAdded');
  } else {
    yield call(eventBus.error, {
      name: 'add-image-to-whiteboard',
      message:
        'Failed to process the file. Please check the provided base64 string or URL and try again.',
    });
  }
}
