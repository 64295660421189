import Input from 'components/Input';
import { Box, MenuItem, Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import InputLabel from 'components/InputLabel';

export interface OptionType {
  value: string;
  label: string;
}

export type SelectProps = Omit<MuiSelectProps<any>, 'displayEmpty' | 'input'> & {
  placeholder?: string;
};

const StyledSelect = styled((props: SelectProps) => (
  <MuiSelect {...props} input={<Input />} displayEmpty={!!props.placeholder} />
))(({ theme, placeholder, value }) => ({
  '& .MuiInputBase-input.MuiSelect-select': {
    width: '100%',
    minHeight: 0,
    color: placeholder && !value ? theme.palette.grey['600'] : theme.palette.text.primary,
    '&:focus': {
      borderRadius: 4,
    },
  },
}));

// @FIXME use generic type instead of any in MuiSelectProps<any>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Select = <T extends unknown>({
  placeholder,
  children,
  labelId,
  label,
  ...props
}: SelectProps) => (
  <>
    {labelId && label ? (
      <Box sx={visuallyHidden}>
        <InputLabel id={labelId}>{label}</InputLabel>
      </Box>
    ) : null}
    <StyledSelect {...props} placeholder={placeholder} labelId={labelId}>
      {placeholder && (
        <MenuItem hidden disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {children}
    </StyledSelect>
  </>
);

export default Select;
